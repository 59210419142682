import axios from 'axios';

export async function ListAllJuris(q, page) {
    try {
        let baseURL = `${process.env.REACT_APP_API_JURIS}/busca?per_page=10`;
        if (q) {
            baseURL = `${baseURL}&q=${q}`
        }
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        const response = await axios.get(baseURL, {
            headers: {
                'Authorization': process.env.REACT_APP_TOKEN_JURIS,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function ListOneJuris(url) {
  try {
      const response = await axios.get(url, {
          headers: {
            'Authorization': process.env.REACT_APP_TOKEN_JURIS,
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}
