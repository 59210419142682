import React, { forwardRef, useEffect, useRef } from 'react';
import { Quill } from 'react-quill';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': [] }],
    [{ 'size': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'align': [] }],
    ['link', 'image', 'video', 'formula'],
    ['clean'],
  ],
};

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
  'color', 'background',
  'script', 'sub', 'super',
  'list', 'bullet', 'indent', 'direction', 'align',
  'link', 'image', 'video', 'formula'
];

const Editor = forwardRef(
  ({ defaultValue, onChange }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: modules,
        formats: formats,
      });

      ref.current = quill;

      // Configura o valor inicial, caso exista
      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      // Monitorando mudanças no conteúdo do editor
      quill.on('text-change', () => {
        if (onChange) {
          onChange(quill.root.innerHTML); // Enviar conteúdo para o pai
        }
      });

      editorContainer.style.padding = '30px';

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [onChange, ref]);

    return <div ref={containerRef}></div>;
  }
);

Editor.displayName = 'Editor';

export default Editor;
